// apps/vertice/src/app/modules/login/auth-resolver.service.ts
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthResolver implements Resolve<boolean> {
    constructor(
        private translateService: TranslateService,
    ) { }

    resolve(): Observable<boolean> {
        // Ensure translations are loaded before showing the form
        return this.translateService.use('pt').pipe(
            // Small delay to ensure Material styles are applied
            delay(100),
            tap(() => {
                // Force Material styles to be applied
                document.body.classList.add('mat-typography');
            })
        );
    }
}