

import { Component, Injector, OnInit } from '@angular/core';
// import { IconService } from 'apps/vertice/services/icon.service';
// import { Intercom } from 'ng-intercom-angular-ten';
import { updateInjector } from './shared/modules/confirmation/confirmable.function';

@Component({
  selector: 've-root',
  templateUrl: './app.component.html',
  // standalone: true,
})
export class AppComponent implements OnInit {
  constructor(
    private injector: Injector,
    // private iconService: IconService,
    // private intercom: Intercom,
  ) {
    updateInjector(injector);
  }
  title = 'vertice';

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.iconService.registerIcons();
  }
}



// import { Component } from '@angular/core';
// import { RouterModule } from '@angular/router';
// import { NxWelcomeComponent } from './nx-welcome.component';

// @Component({
//   standalone: true,
//   imports: [NxWelcomeComponent, RouterModule],
//   selector: 've-root',
//   templateUrl: './app.component.html',
//   styleUrl: './app.component.scss',
// })
// // export class AppComponent {
// //   title = 'vertice';
// // }
// export class AppComponent implements OnInit {
//   constructor(
//     private injector: Injector,
//     private iconService: IconService,
//     // private intercom: Intercom,
//     public breadcrumbsService: BreadcrumbsService
//   ) {
//     updateInjector(injector);
//   }
//   title = 'blueghost';